import * as React from "react";
import { NextPage } from "next";

import { BaseScreen, BlurCard, LinkButton, Title } from "client/components";
import { textAlign } from "three-text2d";

const Index: NextPage = () => {
  return (
    <BaseScreen
      showEntities
      showStars
      headerTitle={"BORED SPACE GAMES: Not Found"}
    >
      <div style={{ padding: 12 }}>
        <BlurCard
          style={{
            maxWidth: 600,
            minWidth: "80%",
            margin: "auto",
          }}
        >
          <Title title="404 Not Found" />
          <p style={{ textAlign: "center" }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            The page you're looking for does not exist.
          </p>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LinkButton link="/" title="Go to Main Menu" />
          </div>
        </BlurCard>
      </div>
    </BaseScreen>
  );
};

export default Index;
